.nav-link{
    background: #00516D !important;
    color: white !important;
    font-size: 18px !important;
    font-weight: bold !important;
    border-radius: 0px !important;
  
}

.nav-link.active{
    background: #00ABE8 !important;
}
.item {
    cursor: pointer;
    transition: 0.05s ease-in-out;
}

.item:hover {
    color: var(--orange);
    transform: scale(1.03);
    border-bottom: 3px solid var(--orange);   
}

.navigation a {
    color: inherit !important;
    text-decoration: none !important;
  }
  
.active-link {
    border-bottom: 3px solid var(--orange) !important;
    color: var(--orange) !important;
    
  }
  
  

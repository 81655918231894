.Title {
   
    font-weight: bold;
    font-size: 55px;
        
}

.crd {
    box-shadow: 0px 3px 6px 0px rgba(30, 0, 127, 0.1);
}

@media (max-width: 768px) {
    .Center {
        display: flex;
        align-items: center !important;
        justify-content: center !important;
    }
}
.card {
    border-radius: 15px;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s ease-in-out;
    cursor: pointer;
}



.card:hover{
    filter: blur(5px);
    
}
.card:hover .logo {
    filter: none !important;
}


.Title {
    font-weight: bold;
    font-size: 55px;
}

.Title2 {
    font-size: 45px;
    font-weight: bold;
}

.Learn-button {
    background-image: linear-gradient(to left,#033988, #009EE2);
    color: white;
    font-size: 16px;
    border: none;
    font-weight: 200;
}

.Slider {
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.192);
    border-radius: 25px;
}
.swiper-pagination{
    width: 50% !important;
    margin-left: 31.2%;
 
    .swiper-pagination-bullet-active {
        width: 5%;
        border-radius: 5px;
        transition: 0.1s;
        background-image: linear-gradient(to left,#033988, #009EE2);
    }
}
.swiper-pagination-bullet {
    transition: 0.1s ease-in-out;
}

.Card1 {
    color: white;
    background: none;
}

.Card1:hover {
    color: black !important;
    background: white !important;
}

a {
    text-decoration: none !important;
    color: inherit !important;
}
.Foot {
    
    height: 800px !important;
}

@media (max-width: 768px) {

    .Center {
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        text-align: center !important;
    }
    .Foot {
        
        height: 1300px !important;
    }
}